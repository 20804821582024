import React, { FC, createContext } from 'react';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance } from 'axios';
import { App } from '@cflin/interfaces';
import camelcaseKeys from 'camelcase-keys';
import { Permission } from '@cflin/auth';
import { UserInfo } from './UserInfo';
import { jwt } from './jwt';

export interface AuthContextProps {
  isLoading: boolean;
  isAuthenticated: boolean;
  loginWithRedirect(options?: RedirectLoginOptions): Promise<void>;
  userInfo: Partial<UserInfo>;
  getPermissions(aud: App): Promise<Permission[]>;
  getAuthAxios(aud: App): AxiosInstance;
}
export const AuthContext = createContext<AuthContextProps>(undefined!);

export const AuthUtilsProvider: FC = ({ children }) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    // getAccessTokenWithPopup,
    user,
  } = useAuth0();

  const userInfo = camelcaseKeys(user!);

  const authAxios: Record<App, AxiosInstance> = {} as Record<App, AxiosInstance>;
  const getAuthAxios = (aud: App) => {
    if (!(aud in authAxios)) {
      const axiosInst = axios.create({});
      axiosInst.interceptors.request.use(
        async req => {
          req.headers.Authorization = `bearer ${await getAccessTokenSilently({ audience: aud })}`;
          return req;
        },
        async err => Promise.reject(err),
      );
      authAxios[aud] = axiosInst;
    }
    return authAxios[aud];
  };

  const getPermissions = async (audience: App): Promise<Permission[]> => {
    try {
      const decoded = await jwt.decode(await getAccessTokenSilently({ audience })) as { permissions?: Permission[] };
      return decoded.permissions || [];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        userInfo,
        getPermissions,
        getAuthAxios,
      }}
    >
      { children }
    </AuthContext.Provider>
  );
};
