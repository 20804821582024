import React, { FC, createContext } from 'react';
import joinUrl from 'url-join';
import FileType, { FileExtension, MimeType } from 'file-type/browser';
import axios from 'axios';
import { UploadApiResponse } from 'cloudinary';
import dayjs from 'dayjs';
import { config } from '@cflin/config';
import { App } from '@cflin/interfaces';
import { useAuth } from '../auth';

const ALLOWED_EXTENTIONS: (FileExtension | undefined)[] = ['jpg', 'png', 'gif'];
const ALLOWED_MIME_TYPES: (MimeType | undefined)[] = ['image/jpeg', 'image/png', 'image/gif'];

export type CloudinaryUploadApiResponse = UploadApiResponse;

/* eslint-disable camelcase */
interface SignatureRequest {
  timestamp: string;
  folder?: string;
  public_id?: string;
}

interface CloudinaryUploadPost extends SignatureRequest {
  file: Blob;
  api_key: string;
  timestamp: string;
  signature: string;
  upload_preset?: string;
}
/* eslint-enable camelcase */

interface ImgUploadContextProps {
  uploadImg: (blobUrl: string, preset?: string) => Promise<CloudinaryUploadApiResponse>;
}
export const ImgUploadContext = createContext<ImgUploadContextProps>(undefined!);

export const ImgUploadProvider: FC = ({ children }) => {
  const appName = window.location.pathname.split('/')[1];
  const signatureUrl = joinUrl(config.cloudinary.signatureApi, appName);
  const cloudinaryApiUrl = config.cloudinary.uploadApi;
  const authAxios = useAuth().getAuthAxios(appName as App);

  const uploadToCloudinary = async (req: CloudinaryUploadPost): Promise<CloudinaryUploadApiResponse> => {
    const dataForm = new FormData();
    Object.entries(req).forEach(([k, v]) => dataForm.append(k, v));

    return (await axios.post(cloudinaryApiUrl, dataForm)).data as CloudinaryUploadApiResponse;
  };

  const uploadImg = async (blobUrl: string, preset?: string): Promise<CloudinaryUploadApiResponse> => {
    // check file type
    const blob = await (await fetch(blobUrl)).blob();
    const { ext, mime } = await FileType.fromBlob(blob) || {};
    if (!ALLOWED_EXTENTIONS.includes(ext)) throw Error('Invalid extension.');
    if (!ALLOWED_MIME_TYPES.includes(mime)) throw Error('Invalid Mime type.');

    // signature
    const signReq: SignatureRequest = {
      timestamp: dayjs().unix().toString(),
      folder: 'tmp',
      ...(preset && { upload_preset: preset }),
    };
    const signature = (await authAxios.post(
      signatureUrl,
      signReq,
    )).data;

    // cloudinary
    return uploadToCloudinary({
      file: blob,
      api_key: config.cloudinary.apiKey,
      signature,
      ...signReq,
    });
  };

  return (
    <ImgUploadContext.Provider value={{ uploadImg }}>
      {children}
    </ImgUploadContext.Provider>
  );
};
