/* eslint-disable */
import React, { FC } from 'react';
import { FSM, Bet, useSlot, INIT_BETS } from './SlotContext';
import { X2, X5, X10, X15, X20, X25, X30, X40, X100, HIT_ICONS } from './Icon';
import { Led } from './Led';
import './BetPanel.scss';

export const BetPanel: FC = () => {
  const { fsm, hits, bets, betOn, credit } = useSlot();
  const _betHilights = {...INIT_BETS};
  hits.forEach(h => !!HIT_ICONS[h] && _betHilights[HIT_ICONS[h]]++);
  const buttons: { x: Bet, icon: any }[] = [
    { x: 5, icon: <X5 on={_betHilights[5] !== 0} /> },
    { x: 25, icon: <X25 on={_betHilights[25] !== 0} /> },
    { x: 30, icon: <X30 on={_betHilights[30] !== 0} /> },
    { x: 40, icon: <X40 on={_betHilights[40] !== 0} /> },
    { x: 100, icon: <X100 on={_betHilights[100] !== 0 || _betHilights[50] !== 0} /> },
    { x: 20, icon: <X20 on={_betHilights[20] !== 0} /> },
    { x: 15, icon: <X15 on={_betHilights[15] !== 0} /> },
    { x: 10, icon: <X10 on={_betHilights[10] !== 0} /> },
    { x: 2, icon: <X2 on={_betHilights[2] !== 0} /> },
  ];
  const isClickable = fsm === FSM.BETTING && credit > 0;

  return (
    <div className="bet-panel">
      <table>
        <tbody>
          <tr>{buttons.map(b => <td key={b.x} className="name">{b.x}</td>)}</tr>
          <tr>{buttons.map(b => (
            <td
              onClick={isClickable ? () => betOn(b.x) : undefined}
              key={b.x}
              className={isClickable ? 'is-clickable' : 'disabled'}
            >
              {b.icon}
            </td>
          ))}</tr>
          <tr>{buttons.map(b => <td key={b.x}><Led digits={2} value={bets[b.x]!} /></td>)}</tr>
        </tbody>
      </table>
    </div>
  );
};
