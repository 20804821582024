import React, { FC, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';
import { CreatePost } from '@cflin/interfaces';
import { ConfirmAlert } from '../../utils/confirmAlert';
import { View } from './View';

interface Props {
  post?: CreatePost,
  savePost: (path: string, post: CreatePost) => Promise<void>;
  onCancel?: () => void;
}

export const Edit: FC<Props> = ({ post, savePost, onCancel }) => {
  const [editing, setEditing] = useState<boolean>(true);
  const [title, setTitle] = useState<string>(post?.title ?? '');
  const [subtitle, setSubtitle] = useState<string>(post?.subtitle ?? '');
  const [content, setContent] = useState<string>(post?.content ?? '');
  const [tagStr, setTagStr] = useState<string>('');
  const [tags, setTags] = useState<string[]>(post?.tags ?? []);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<string>(undefined!);
  const path = useParams()['*'];
  const addTag = () => {
    if (tagStr.length > 0) {
      setTagStr('');
      setTags(update(tags, { $push: [tagStr] }));
    }
  };

  const edit = (
    <div className="editor">
      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="subtitle"
            value={subtitle}
            onChange={e => setSubtitle(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <textarea
            className="textarea"
            placeholder="content"
            rows={10}
            value={content}
            onChange={e => setContent(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="tags"
            value={tagStr}
            onChange={e => setTagStr(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && addTag()}
          />
        </div>
      </div>
      <span className="tags">
        {tags.map((t, i) => (
          <span
            className="tag is-warning pointer is-clickable"
            key={t}
            onClick={_.partial(setTags, update(tags, { $splice: [[i, 1]] }))}
          >
            {t}
          </span>
        ))}
      </span>
    </div>
  );
  const preview = <View post={{
    title,
    subtitle,
    content,
    tags,
    views: 0,
    likes: 0,
    dislikes: 0,
    uid: '',
    path,
    createdAt: new Date(),
  }}
  />;
  const onSave = async () => {
    setSaving(true);
    try {
      await savePost(path, { title, subtitle, content, tags });
      window.location.reload();
    } catch (e: any) {
      setErrorSaving(`${e.response.status} - ${e.response.statusText}`);
      setSaving(false);
    }
  };

  return (
    <div className="container">
      <div className="tabs is-boxed">
        <ul>
          <li className={editing ? 'is-active' : ''}>
            <a onClick={() => setEditing(true)}>
              <span>Edit</span>
            </a>
          </li>
          <li className={editing ? '' : 'is-active'}>
            <a onClick={() => setEditing(false)}>
              <span>Preview</span>
            </a>
          </li>
        </ul>
      </div>
      { editing ? edit : preview }
      <hr />
      <div className="buttons is-right">
        <button
          type="button"
          className="button is-link is-outlined"
          disabled={!onCancel || saving}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button is-link"
          disabled={saving}
          onClick={onSave}
        >
          Save
        </button>
      </div>
      {!!errorSaving &&
        <ConfirmAlert
          title="Error"
          confirmText="OK"
          onConfirm={async () => setErrorSaving(undefined!)}
          onCancel={async () => setErrorSaving(undefined!)}
        >
          {errorSaving}
        </ConfirmAlert>}
    </div>
  );
};
