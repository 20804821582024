import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Post } from '@cflin/interfaces';
import { Footer } from './Footer';

export const View: FC<{ post: Post }> = ({ post }) => (
  <div className="content">
    <h1>{post.title}</h1>
    <h4>{post.subtitle}</h4>
    <ReactMarkdown remarkPlugins={[remarkGfm]}>
      {post.content}
    </ReactMarkdown>
    <Footer {...post} votable />
  </div>
);
