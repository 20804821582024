import React, { FC } from 'react';
import { Recipe } from '@cflin/interfaces/recipes';
import { Img } from '../../utils/image';

interface Props {
  recipe: Recipe;
}

export const RecipeCard: FC<Props> = ({ recipe }) => (
  <div className="card">
    <div className="card-image">
      <figure className="image is-4by3">
        <Img publicId={recipe.coverImg} />
      </figure>
    </div>
    <div className="card-content">
      <div className="content">
        <h4>{recipe.headline}</h4>
        <p>{recipe.description}</p>
      </div>
    </div>
  </div>
);
