/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { plainToClass } from 'class-transformer';
import { validate, validateSync } from 'class-validator';
import update from 'immutability-helper';
import { Recipe, CreateRecipe, RecipeIngredientGroup as IngredientGroup, RecipeStep as Step } from '@cflin/interfaces';
import { ImgDropzone } from '../../utils/dropzone';
import { getImgUrl, useImg } from '../../utils/image';
import { IngredientGroups } from './IngredientGroups';
import { useRecipeApi } from '../../utils/api';
import { Steps } from './Steps';
import './Editor.scss';

interface Props {
  mode: 'create' | 'update';
}

export const Editor: FC<Props> = ({ mode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [headline, setHeadline] = useState<string>('');
  const [coverImg, setCoverImg] = useState<string>(undefined!);
  const [description, setDescription] = useState<string>('');
  const [servings, setServings] = useState<number>(1);
  const [ingredientGroups, setIngredientGroups] = useState<IngredientGroup[]>([
    {
      groupName: '',
      ingredients: [
        { name: '', quantity: '' },
      ],
    },
  ]);
  const [steps, setSteps] = useState<Step[]>([{ description: '' }]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { uploadImg } = useImg();
  const { getRecipe, createRecipe, updateRecipe } = useRecipeApi();
  const createRecipeRequest: CreateRecipe = plainToClass(CreateRecipe, {
    headline,
    coverImg,
    description,
    servings,
    ingredientGroups,
    steps,
  });
  const isValidRequest = validateSync(createRecipeRequest).length === 0;
  const buttonDisabled = submitting || !isValidRequest;
  const initRecipe = async (recipe: Recipe) => {
    setHeadline(recipe.headline);
    setCoverImg(recipe.coverImg);
    setDescription(recipe.description);
    setServings(recipe.servings);
    setIngredientGroups(recipe.ingredientGroups);
    setSteps(recipe.steps);
  };
  const onCancel = () => { navigate('..') };
  const onSubmit = async () => {
    setSubmitting(true);
    // TODO: compress image on browser side
    // upload cover image and step images
    const coverImgPromise = coverImg.startsWith('blob:') ? 
      uploadImg(coverImg, 'recipe') : Promise.resolve({ public_id: coverImg });
    const [stepIndices = [], stepImgs = []] = _.unzip(
      steps
        .map((s, i) => [i, s.img])
        .filter(([i, img]) => img)
    );

    const stepImgUploadPromises = stepImgs.map(
      img => (img as string).startsWith('blob:') ?
        uploadImg(img as string, 'recipe') : Promise.resolve({ public_id: img }));
    const req = update(
      createRecipeRequest,
      {
        coverImg: { $set: (await coverImgPromise).public_id },
        steps: Object.fromEntries(
          _.zip(
            stepIndices,
            (await Promise.all(stepImgUploadPromises)).map(res => ({ img: { $set: res.public_id } })),
          ),
        ),
      },
    );
    navigate(`/recipes/${await ({ create: createRecipe, update: _.partial(updateRecipe, id) })[mode](req)}`);
    // TODO: release URL objects
  };

  useEffect(() => {(async () => {
    mode === 'update' && id && initRecipe(await getRecipe(id));
  })()}, []);

  return (
    <div className="editor columns">
      <div className="column is-8 is-offset-1">
        <div className="section">
          <div className="container box">
            {/* header */}
            <div className="field">
              <input
                className="input"
                type="text"
                placeholder="Headline"
                value={headline}
                onChange={e => setHeadline(e.target.value)}
              />
              <hr />
              <div className="cover-img">
                <ImgDropzone
                  src={getImgUrl(coverImg)}
                  onDrop={setCoverImg}
                  onDelete={() => setCoverImg(undefined!)}
                />
              </div>
            </div>

            {/* Description */}
            <div className="field">
              <label className="label">Description</label>
              <textarea className="textarea" value={description} onChange={e => setDescription(e.target.value)} />
            </div>

            {/* Servings */}
            <div className="field">
              <label className="label">Servings</label>
              <div className="select is-rounded is-small">
                <select onChange={e => setServings(Number(e.target.value))}>
                  {_.range(10).map(i => <option key={i}>{i + 1}</option>)}
                </select>
              </div>
            </div>

            {/* Ingredients */}
            <div className="field">
              <label className="label">Ingredients</label>
              <IngredientGroups
                ingredientGroups={ingredientGroups}
                setIngredientGroups={setIngredientGroups}
              />
            </div>

            {/* Steps */}
            <div className="field">
              <label className="label">Steps</label>
              <Steps steps={steps} setSteps={setSteps} />
            </div>

            {/* Save Button */}
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button className="button is-link" disabled={buttonDisabled} type="button" onClick={onSubmit}>Submit</button>
              </div>
              <div className="control">
                <button className="button is-link is-light" disabled={buttonDisabled} type="button" onClick={onCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
