/* eslint-disable */
import React, { FC, useState } from 'react';
import { useSlot, SlotContextProvider } from './SlotContext';
import { CreditPanel } from './CreditPanel';
import { Carousel } from './Carousel';
import { BetPanel } from './BetPanel';
import { ControlPanel } from './ControlPanel';

export const Slot: FC = () => {
  const { spin } = useSlot();
  return (
    <div className="slot">
      <div className="section">
        <CreditPanel />
        <Carousel />
        <BetPanel />
        <ControlPanel />
      </div>
    </div>
  );
};
