import React, { useState, FC, MutableRefObject } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus as faNewEntry } from '@fortawesome/free-regular-svg-icons';
import { DiaryEntry, CreateDiaryEntry } from '@cflin/interfaces/diary';
import { useDiary } from './DiaryContext';
import { EntryContainer } from './EntryContainer';
import { Editor } from './Editor';
import './TimelineItem.scss';

interface Props {
  date: string;
  entries: DiaryEntry[];
  dateIndex: number;
  anchor?: MutableRefObject<HTMLDivElement>;
}

export const TimelineItem: FC<Props> = ({ date, entries, dateIndex, anchor }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const { today, addEntry } = useDiary();
  const onSavingNewEntry = async (entry: CreateDiaryEntry): Promise<void> => {
    await addEntry(dateIndex, entry);
    setEditing(false);
  };

  return (
    <div className="timeline-item" ref={anchor}>
      <div className={`timeline-marker ${dayjs.utc(date).isAfter(today) ? 'is-grey' : 'is-primary'}`} />
      <div className="timeline-content container">
        <p className="heading is-size-6">
          {date}
          <span className="new-entry-icon">
            <FontAwesomeIcon icon={faNewEntry} onClick={async () => setEditing(!editing)} />
          </span>
        </p>
        <div className="columns is-multiline">
          {editing && (
            <div className="column is-half" key="__editor">
              <Editor onSave={onSavingNewEntry} onCancel={async () => setEditing(false)} />
            </div>
          )}
          {entries.map((entry, i) => (
            <div className="column is-half" key={entry.id}>
              <EntryContainer dateIndex={dateIndex} entryIndex={i} entry={entry} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
