import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from '@cflin/config';
import { AuthContext, AuthUtilsProvider } from './AuthUtilsProvider';

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={appState => navigate(appState?.returnTo || window.location.pathname)}
    >
      <AuthUtilsProvider>
        { children }
      </AuthUtilsProvider>
    </Auth0Provider>
  );
};
