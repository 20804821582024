import _ from 'lodash';

/* eslint-disable indent, no-await-in-loop, no-restricted-syntax */

//                                            ________ L
//                                           |
// I ____                              ______| I
//       \                            /
//        \                          /
//         \                        /
//          \                      /
//         X \____________________/
//      A    B                    C    D     E
const delay = async (t: number) => new Promise(res => setTimeout(res, t));

const getDelayFunc = (M: number) => (n: number) => {
  const I = 250;
  const X = 12;
  const L = 300;
  const A = 0;
  const B = 15;
  const C = 105 + Math.floor(M / 3);
  const D = 120 + Math.floor((M + 1) / 3);
  const E = 125 + Math.floor((M + 2) / 3);
  const FR = (I - X) / (B - A);
  const RR = (I - X) / (D - C);
  return n <=
    A ? I :
    n <= B ? I - FR * (n - A) :
    n <= C ? X :
    n <= D ? X + RR * (n - C) :
    n <= E ? I :
    L;
};

const onceMore = async (init: number, setHits: (hits: number[]) => void) => {
  // generate random 3 numbers among [0, 23] other than [9, 21]
  const picked = new Set([9, 21]);
  while (picked.size !== 5) picked.add(_.random(0, 23));
  [9, 21].forEach(h => picked.delete(h));
  let hits = [init];
  let clockwise = false;
  for (const p of picked) {
    let i = init;
    while (i !== p) {
      await delay(120);
      i = (i + 24 + (clockwise ? 1 : -1)) % 24;
      setHits([...hits, i]);
    }
    hits = [...hits, p];
    clockwise = !clockwise;
  }
  return hits;
};
export const spin = async (init: number, setHits: (hits: number[]) => void) => {
  let cur = init;
  const seed = _.random(23);
  const delayFunc = getDelayFunc(seed);
  for (let i = 0; i < 130 + seed; i++) {
    await delay(delayFunc(i));
    setHits([cur = ((cur + 1) % 24)]);
  }
  if (cur === 9 || cur === 21) return onceMore(cur, setHits);
  return [cur];
};
