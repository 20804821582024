import React, { FC, Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'cloudinary-react';
import { useAuth0 } from '@auth0/auth0-react';
import { Recipe as RecipeDto } from '@cflin/interfaces';
import { useRecipeApi } from '../../utils/api';
import './Recipe.scss';

/* eslint-disable */

export const Recipe: FC = () => {
  const [recipe, setRecipe] = useState<RecipeDto>(undefined!);
  const [disabled, setDisabled] = useState<boolean>();
  const { id } = useParams();
  const { getRecipe, deleteRecipe } = useRecipeApi();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const onEdit = async () => { navigate('./edit'); };
  const onDelete = async () => {
    setDisabled(true);
    await deleteRecipe(id);
    navigate('/recipes');
  };

  useEffect(() => { (async () => setRecipe(await getRecipe(id)))(); }, []);

  if (!recipe) return <></>;

  const header = (
    <div className="header box">
      <h1 className="title is-4">{recipe.headline}</h1>
      <hr />
      <div className="columns">
        <div className="column">
          <Image cloudName="cflin" publicId={recipe.coverImg} />
        </div>
        <div className="column is-two-fifths">
          <div>{recipe.description}</div>
        </div>
      </div>
    </div>
  );

  const ingredients = (
    <div className="ingredients box">
      { recipe.ingredientGroups.map(group => (
        <div className="ingredient-group" key={group.groupName}>
          <div className="title is-5">{group.groupName}</div>
          <div className="columns is-multiline is-5">
            {group.ingredients.map(ingredient => (
              <div className="column is-half" key={ingredient.name}>
                <div className="ingredient">
                  <div className="ingredient-name is-pulled-left">{ingredient.name}</div>
                  <div className="ingredient-quantity is-pulled-right">{ingredient.quantity}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )) }
    </div>
  );

  const steps = (
    <div className="steps box">
      <h1 className="title is-4">Steps</h1>
      { recipe.steps.map((step, s) => (
        <Fragment key={step.description}>
          <div className="step columns">
            <div className="column is-one-third">
              <Image cloudName="cflin" publicId={step.img} />
            </div>
            <div className="column">
              <div>
                <h3 className="title is-3">{s + 1}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          </div>
          { s !== recipe.steps.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  );

  const control = user?.sub === recipe.uid ?
    <div className="field is-grouped is-pulled-right">
      <div className="control">
        <button className="button" disabled={disabled} onClick={onEdit}>Edit</button>
      </div>
      <div className="control">
        <button className="button is-danger" disabled={disabled} onClick={onDelete}>Delete</button>
      </div>
    </div>
    :
      <></>
    ;

  return (
    <div className="recipe">
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              {header}
              {ingredients}
              {steps}
              {control}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
