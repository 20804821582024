/* eslint-disable */
import React, { FC, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Carousel, { ViewType, Modal, ModalGateway } from 'react-images';
import Gallery, { PhotoProps } from 'react-photo-gallery';

const baseUrl = "https://res.cloudinary.com/cflin/image/upload";

interface CloudinaryGallery {
  resources: {
    public_id: string;
    width: number;
    height: number;
    context: {
      custom: {
        caption: string;
        context: string;
        date: string;
      }
    }
  }[];
}

const Index: FC = () => {
  const [overviewImgs, setOverviewImgs] = useState<PhotoProps[]>([]);
  const [lightBoxViews, setLightBoxViews] = useState<ViewType[]>([]);
  const [] = useState<string>();
  const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);

  // eslint-disable-next-line
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    (async () => {
      const gallery = (await axios.get('https://res.cloudinary.com/cflin/image/list/gallery.json')).data as CloudinaryGallery;
      const imgs = gallery.resources.sort((a, b) => b.context.custom.date.localeCompare(a.context.custom.date));
      setOverviewImgs(gallery.resources.map(r => ({
        src: `${baseUrl}/h_300/${r.public_id}`,
        width: r.width / r.height * 300,
        height: 300,
      })));
      setLightBoxViews(gallery.resources.map(r => ({
        source: `${baseUrl}/${r.public_id}`,
        alt: r.context.custom.caption
      })));
    })();
  }, []);

  return (
    <div>
      <Gallery photos={overviewImgs} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={lightBoxViews}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default Index;
