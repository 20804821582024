import React, { FC } from 'react';

export interface Props {
  title: string;
  confirmText: string;
  onConfirm: () => Promise<void>;
  onCancel: () => Promise<void>;
}

export const ConfirmAlert: FC<Props> = ({ title, children, confirmText, onConfirm, onCancel }) => (
  <div className="modal is-active">
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button type="button" className="delete" aria-label="close" onClick={onCancel} />
      </header>
      <section className="modal-card-body">
        {children}
      </section>
      <footer className="modal-card-foot">
        <button type="button" className="button is-danger" onClick={onConfirm}>{confirmText}</button>
        <button type="button" className="button" onClick={onCancel}>Cancel</button>
      </footer>
    </div>
  </div>
);
