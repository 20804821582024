/* eslint-disable max-len, no-multi-spaces,
    computed-property-spacing, react/no-array-index-key */
import React, { FC } from 'react';
import { X2, X5, X10, X15, X20, X25, X30, X40, X50, X100, OnceMore } from './Icon';
import { useSlot } from './SlotContext';
import './Carousel.scss';

const genIcon = (Icon: FC<{on?: boolean}>, on: boolean) => <Icon {...on ? { on } : {}} />;

export const Carousel: FC = () => {
  const hits = new Set(useSlot().hits);
  const ic = [
    X10, X20, X50, X100, X5, X2,
    X15, X25, X2, OnceMore, X5, X2,
    X10, X20, X2, X40, X5, X2,
    X15, X30, X2, OnceMore, X5, X2,
  ].map((icon, i) => genIcon(icon, hits.has(i)));
  const table = [
    [ic[ 0], ic[ 1], ic[ 2], ic[ 3], ic[ 4], ic[ 5], ic[ 6]],
    [ic[23], null,   null,   null,   null,   null,   ic[ 7]],
    [ic[22], null,   null,   null,   null,   null,   ic[ 8]],
    [ic[21], null,   null,   null,   null,   null,   ic[ 9]],
    [ic[20], null,   null,   null,   null,   null,   ic[10]],
    [ic[19], null,   null,   null,   null,   null,   ic[11]],
    [ic[18], ic[17], ic[16], ic[15], ic[14], ic[13], ic[12]],
  ];

  return (
    <div>
      <table className="carousel">
        <tbody>
          {table.map((r, i) => (
            <tr key={i}>
              {r.map((c, j) => <td key={j}>{c}</td>)}
            </tr>))}
        </tbody>
      </table>
    </div>
  );
};
