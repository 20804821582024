import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Login } from './Login';
import logo from './logo-w.png';
import './Header.scss';

const Header: FC = () => {
  const [menuOn, setMenuOn] = useState<boolean>(false);

  return (
    <div className="header">
      <nav className="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img className="logo" src={logo} />
          </a>

          <a
            role="button"
            className={`navbar-burger burger ${menuOn ? 'is-active' : ''}`}
            onClick={() => setMenuOn(!menuOn)}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div className={`navbar-menu ${menuOn ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <Link to="/posts" className="navbar-item" onClick={() => setMenuOn(false)}>Posts</Link>
            <Link to="/gallery" className="navbar-item" onClick={() => setMenuOn(false)}>Gallery</Link>
            <Link to="/recipes" className="navbar-item" onClick={() => setMenuOn(false)}>Recipes</Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                Sandbox
              </a>
              <div className="navbar-dropdown is-boxed">
                <a className="navbar-item" href="/slot">
                  Slot Machine
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <Login />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
