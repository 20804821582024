/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { digit } from './imgs';
import './Led.scss';

interface Props {
  digits: number;
  value: number;
}

export const Led: FC<Props> = ({ digits, value }) => {
  const v = value.toString().slice(-digits);
  const lo = [...('0'.repeat(digits - v.length))]
    .map((p, i) => <img src={digit[p]} className="lo" key={`l-${i}-${p}`} />);
  const hi = [...v.toString()]
    .map((d, i) => <img src={digit[d]} key={`h-${i}-${d}`} />);
  return (<div className="led">{[...lo, ...hi]}</div>);
};
