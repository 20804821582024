import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { usePostApi } from '../../utils/api';
import { Preview } from './Preview';
import { PostRouter } from './PostRouter';

const Index: FC = () => {
  const { getDirectory } = usePostApi();

  return (
    <div className="posts">
      <div className="section">
        <Routes>
          <Route path="/" element={<Preview postsProvider={() => getDirectory('/')} />} />
          <Route path="*" element={<PostRouter />} />
        </Routes>
      </div>
    </div>
  );
};

export default Index;
