import React, { FC, useState } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { DiaryEntry, UpdateDiaryEntry } from '@cflin/interfaces';
import './Editor.scss';

interface Props {
  onSave(entry: UpdateDiaryEntry): Promise<void>;
  onCancel(): Promise<void>;
  entry?: DiaryEntry;
}

export const Editor: FC<Props> = ({ entry, onSave: saveEntry, onCancel }) => {
  const [title, setTitle] = useState<string>(entry?.title || '');
  const [content, setContent] = useState<string>(entry?.content || '');
  const [tagStr, setTagStr] = useState<string>('');
  const [tags, setTags] = useState<string[]>(entry?.tags || []);
  const [disabled, setDisabled] = useState<boolean>(false);
  const addTag = () => {
    if (tagStr.length > 0) {
      setTagStr('');
      setTags(update(tags, { $push: [tagStr] }));
    }
  };
  const getEntry = (): UpdateDiaryEntry => ({
    title,
    content,
    tags,
  });

  const onSave = async () => {
    setDisabled(true);
    await saveEntry(getEntry());
  };

  // useEffect(() => (() => { clearTimeout(timer); }));

  return (
    <div className="editor">
      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <textarea
            className="textarea"
            placeholder="content"
            rows={10}
            value={content}
            onChange={e => setContent(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="tags"
            value={tagStr}
            onChange={e => setTagStr(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && addTag()}
          />
        </div>
      </div>
      <div className="field">
        {tags.map((tag, i) => (
          <button
            type="button"
            className="tag is-warning is-small box"
            key={tag}
            onClick={_.partial(setTags, update(tags, { $splice: [[i, 1]] }))}
          >
            {tag}
          </button>
        ))}
      </div>

      <div className="field has-addons">
        <div className="control">
          <button type="submit" className="button is-link" disabled={disabled} onClick={onSave}>
            Save
          </button>
        </div>
        <div className="control">
          <button type="button" className="button is-link is-light" disabled={disabled} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
