import React, { FC } from 'react';

interface NestedProps {
  components: (FC | [FC, any])[],
}

export const Nested: FC<NestedProps> = ({ components, children }) => {
  if (components.length === 0) return <>{children}</>;

  const [C, props] = typeof components[0] === 'object' ? components[0] : [components[0], {}];

  return (
    <C {...props}>
      <Nested components={components.slice(1)}>
        {children}
      </Nested>
    </C>
  );
};
