/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { Led } from './Led';
import { FSM, useSlot } from './SlotContext';
import './Credit.scss';

export const Credit: FC = () => {
  const { fsm, credit, addCredit } = useSlot();
  const isClickable = fsm === FSM.IDLE || fsm === FSM.BETTING;

  return (
    <div className={`credit ${isClickable ? 'is-clickable' : ''}`} onClick={() => addCredit(10)}>
      <div className="name">Credit</div>
      <div><Led digits={4} value={credit} /></div>
    </div>
  );
};
