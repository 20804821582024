import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Diary } from './Diary';
import { getToday } from './utils';

export const Index: FC = () => (
  <div>
    <Routes>
      <Route path="/" element={<Navigate to={getToday().utc().format('YYYY-MM-DD')} />} />
      <Route path=":date" element={<Diary />} />
    </Routes>
  </div>
);

export default Index;
