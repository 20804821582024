import React, { FC } from 'react';
import { Image } from 'cloudinary-react';
import { config } from '@cflin/config';

interface Props {
  publicId: string;
}

export const Img: FC<Props> = ({ publicId }) => (
  <Image cloudName={config.cloudinary.cloudName} publicId={publicId} />
);
