import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Recipe } from '@cflin/interfaces/recipes';
import { useRecipeApi } from '../../utils/api';
import { RecipeCard } from './RecipeCard';
import './Recipes.scss';

export const Recipes: FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>(undefined!);
  const { getRecipes } = useRecipeApi();
  const recipeCards = recipes && (
    _.chunk(recipes, 4).map(batch => (
      <div className="columns is-multiline" key={batch[0].id}>
        { batch.map(recipe => (
          <div className="column is-one-quarter" key={recipe.id}>
            <Link to={recipe.id}><RecipeCard recipe={recipe} /></Link>
          </div>
        )) }
      </div>
    ))
  );

  useEffect(() => {
    const init = async () => {
      setRecipes(await getRecipes());
    };
    init();
  }, []);

  return (
    <div className="section">
      {recipeCards}
    </div>
  );
};
