import React, { FC } from 'react';
import { x2, x5, x10, x15, x20, x25, x30, x40, x50, x100, onceMore } from './imgs';
import './Icon.scss';

export const HIT_ICONS = [
  10, 20, 50, 100, 5, 2,
  15, 25, 2, 0, 5, 2,
  10, 20, 2, 40, 5, 2,
  15, 30, 2, 0, 5, 2,
];

const genIcon = (img: string): FC<{on?: boolean}> => ({ on }) => (
  <div className={`icon ${on ? 'icon-on' : 'icon-off'}`}>
    <img src={img} />
  </div>
);

export const X2 = genIcon(x2);
export const X5 = genIcon(x5);
export const X10 = genIcon(x10);
export const X15 = genIcon(x15);
export const X20 = genIcon(x20);
export const X25 = genIcon(x25);
export const X30 = genIcon(x30);
export const X40 = genIcon(x40);
export const X50 = genIcon(x50);
export const X100 = genIcon(x100);
export const OnceMore = genIcon(onceMore);
