import React, { FC, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { Permission } from '@cflin/auth';
import { App } from '@cflin/interfaces';
import { useAuth } from './AuthProvider';

type NonEmptyArray<T> = [T, ...T[]]

interface Props extends RouteProps {
  permissions: NonEmptyArray<Permission>;
  redirectToAuth?: boolean;
}

export const AuthRoute: FC<Props> = props => {
  const { permissions: requiredPermissions, redirectToAuth = true } = props;
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth();
  const { getPermissions } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (isLoading) return;
      if (!isAuthenticated) {
        if (redirectToAuth) {
          loginWithRedirect({ appState: { returnTo: window.location.pathname } });
        }
      } else {
        const permissions = await getPermissions(window.location.pathname.split('/')[1] as App);
        setIsAuthorized(!!(requiredPermissions.every(p => permissions.includes(p))));
      }
    })();
  });

  return isAuthorized ? <Route {...props} /> : <></>;
};
