import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Nested as Providers } from './utils/react';
import { AuthProvider, AuthRoute } from './utils/auth';
import { ApiProvider } from './utils/api';
import { ImgProvider } from './utils/image';
import { Header } from './frame';
import Posts from './apps/posts';
import Slot from './apps/slot';
import Gallery from './apps/gallery';
import Recipes from './apps/recipes';
import Diary from './apps/diary';

export const App: FC = () => (
  <Router>
    <Providers components={[AuthProvider, ApiProvider, ImgProvider]}>
      <Header />
      <Routes>
        <Route path="posts/*" element={<Posts />} />
        <Route path="slot/*" element={<Slot />} />
        <Route path="gallery/*" element={<Gallery />} />
        <Route path="recipes/*" element={<Recipes />} />
        <AuthRoute path="diary/*" element={<Diary />} permissions={['diary.access']} redirectToAuth={false} />
      </Routes>
    </Providers>
  </Router>
);
