import React, { FC } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { RecipeStep as Step } from '@cflin/interfaces';
import { getImgUrl } from '../../utils/image';
import { ImgDropzone } from '../../utils/dropzone';

interface Props {
  steps: Step[];
  setSteps: (steps: Step[]) => void;
}

export const Steps: FC<Props> = ({ steps, setSteps }) => {
  const newStep = (i: number) => setSteps(update(steps, {
    $splice: [[i + 1, 0, { description: '' }]],
  }));
  const deleteStep = (i: number) => setSteps(update(steps, {
    $splice: [[i, 1]],
  }));
  const deleteImage = (i: number) => {
    setSteps(update(steps, {
      [i]: { $unset: ['img'] },
    }));
  };
  const updateImage = (i: number, img: string) => {
    setSteps(update(steps, {
      [i]: { img: { $set: img } },
    }));
  };
  const updateDescription = (i: number, description: string) => setSteps(update(steps, {
    [i]: { description: { $set: description } },
  }));

  return (
    <div className="steps">
      {steps.map((step, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          <div className="step">
            <div className="columns">
              <div className="column is-one-third">
                <ImgDropzone
                  src={getImgUrl(step.img)}
                  onDrop={_.partial(updateImage, i)}
                  onDelete={_.partial(deleteImage, i)}
                />
              </div>
              <div className="column">
                <div className="level">
                  <h3 className="title level-left">{i + 1}</h3>
                  <div className="level-right">
                    <FontAwesomeIcon
                      className="op-button"
                      icon={faPlus}
                      onClick={_.partial(newStep, i)}
                    />
                    <FontAwesomeIcon
                      className={`op-button ${steps.length <= 1 ? 'disabled' : ''}`}
                      icon={faTrashAlt}
                      onClick={steps.length > 1 ? _.partial(deleteStep, i) : undefined}
                    />
                  </div>
                </div>
                <textarea
                  className="textarea"
                  rows={4}
                  value={step.description}
                  onChange={e => updateDescription(i, e.target.value)}
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};
