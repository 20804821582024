import d0 from './imgs/0.png';
import d1 from './imgs/1.png';
import d2 from './imgs/2.png';
import d3 from './imgs/3.png';
import d4 from './imgs/4.png';
import d5 from './imgs/5.png';
import d6 from './imgs/6.png';
import d7 from './imgs/7.png';
import d8 from './imgs/8.png';
import d9 from './imgs/9.png';
import x2 from './imgs/x2.png';
import x5 from './imgs/x5.png';
import x10 from './imgs/x10.png';
import x15 from './imgs/x15.png';
import x20 from './imgs/x20.png';
import x25 from './imgs/x25.png';
import x30 from './imgs/x30.png';
import x40 from './imgs/x40.png';
import x50 from './imgs/x50.png';
import x100 from './imgs/x100.png';
import onceMore from './imgs/onceMore.png';

export const digit = [d0, d1, d2, d3, d4, d5, d6, d7, d8, d9];
export {
  x2, x5, x10, x15, x20, x25, x30, x40, x50, x100, onceMore,
};
