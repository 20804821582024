import React, { FC, useContext } from 'react';
import { ImgUploadProvider, ImgUploadContext } from './ImgUploadProvider';

export const useImg = () => ({
  ...useContext(ImgUploadContext),
});

export const ImgProvider: FC = ({ children }) => (
  <ImgUploadProvider>
    { children }
  </ImgUploadProvider>
);
