import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PostPreview } from '@cflin/interfaces';
import { Footer } from './Footer';

interface Props {
  postsProvider: () => Promise<PostPreview[]>;
}

export const Preview: FC<Props> = ({ postsProvider }) => {
  const [posts, setPosts] = useState<PostPreview[]>([]);

  useEffect(() => {
    (async () => {
      setPosts(await postsProvider());
    })();
  }, []);

  return (
    <div className="container">
      <div className="content">
        {posts.map(p => (
          <div key={p.title}>
            <h3>
              <Link to={p.path.substring(1)}>{p.title}</Link>
            </h3>
            <h5>{p.subtitle}</h5>
            <Footer {...p} />
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};
