import React, { FC, useContext } from 'react';
import { RecipeApiContext, RecipeApiProvider } from './RecipeApiProvider';
import { DiaryApiContext, DiaryApiProvider } from './DiaryApiProvider';
import { PostApiContext, PostApiProvider } from './PostApiProvider';
import { Nested } from '../react';

export const useRecipeApi = () => useContext(RecipeApiContext);
export const useDiaryApi = () => useContext(DiaryApiContext);
export const usePostApi = () => useContext(PostApiContext);

export const ApiProvider: FC = ({ children }) => (
  <Nested components={[RecipeApiProvider, DiaryApiProvider, PostApiProvider]}>
    {children}
  </Nested>
);
