import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Recipes } from './Recipes';
import { Recipe } from './Recipe';
import { Editor } from './Editor';
import { AuthRoute } from '../../utils/auth';

const Index: FC = () => (
  <div className="recipes">
    <Routes>
      <Route path="/" element={<Recipes />} />
      <Route path=":id" element={<Recipe />} />
      <AuthRoute
        path="new"
        element={<Editor mode="create" />}
        permissions={['recipe.create']}
      />
      <AuthRoute
        path=":id/edit"
        element={<Editor mode="update" />}
        permissions={['recipe.update']}
        redirectToAuth={false}
      />
    </Routes>
  </div>
);

export default Index;
