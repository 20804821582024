import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Post as PostDto } from '@cflin/interfaces';
import { useAuth } from '../../utils/auth';
import { usePostApi } from '../../utils/api';
import { ConfirmAlert } from '../../utils/confirmAlert';
import { View } from './View';
import { Edit } from './Edit';

interface Props {
  postProvider: () => Promise<PostDto>;
}

export const Post: FC<Props> = ({ postProvider }) => {
  const [post, setPost] = useState<PostDto>(undefined!);
  const [creating, setCreating] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [delWarn, setDelWarn] = useState<boolean>(false);
  const path = useParams()['*'];
  const { createPost, updatePost, deletePost } = usePostApi();
  const { getPermissions } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const canEdit = !!(user?.sub) && user?.sub === post?.uid;

  useEffect(
    () => {
      (async () => {
        let p;
        try {
          p = await postProvider();
        } catch (e: any) {
          if (e.response.status === 404 &&
            (await getPermissions('posts')).includes('post.create')) setCreating(true);
          else throw e;
        }
        setPost(p);
      })();
    },
    [],
  );

  if (creating) return <Edit savePost={createPost} />;
  if (updating) return <Edit post={post} savePost={updatePost} onCancel={() => setUpdating(false)} />;
  if (!post) return <></>;

  return (
    <div className="container">
      <View post={post} />
      {
        canEdit && (
          <>
            <div className="buttons">
              <button type="button" className="button is-warning" onClick={() => setUpdating(true)}>
                Update
              </button>
              <button type="button" className="button is-danger" onClick={() => setDelWarn(true)}>
                Delete
              </button>
            </div>
            {delWarn && (
              <ConfirmAlert
                title="WARNING"
                confirmText="Delete"
                onConfirm={async () => {
                  await deletePost(path);
                  navigate('/posts');
                }}
                onCancel={async () => setDelWarn(false)}
              >
                Are you sure you want to delete this post??
              </ConfirmAlert>)}
          </>
        )
      }
    </div>
  );
};
