import React, { FC, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePostApi } from '../../utils/api';
import { Post } from './Post';
import { Preview } from './Preview';

export const PostRouter: FC = () => {
  const { getPost, getDirectory } = usePostApi();
  const navigate = useNavigate();
  const path = useParams()['*'];

  useEffect(() => {
    if (path !== path.toLowerCase()) navigate(path.toLowerCase().substring(1));
  }, []);

  switch (true) {
    case path.endsWith('/'):
      return (
        <Preview postsProvider={
          async () => {
            try {
              return await getDirectory(path);
            } catch (e) {
              return [];
            }
          }
        }
        />
      );
    default:
      return <Post postProvider={() => getPost(path)} />;
  }
};
