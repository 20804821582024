import React, { FC, createContext } from 'react';
import joinUrl from 'url-join';
import { CreateDiaryEntry, UpdateDiaryEntry, DiaryEntry, DateEntries } from '@cflin/interfaces';
import { config } from '@cflin/config';
import { useAuth } from '../auth';

export interface DiaryContextProps {
  getDiaryEntries(date: string): Promise<DateEntries[]>;
  getDiaryEntries(from: string, to: string): Promise<DateEntries[]>;
  createDiaryEntry(date: string, req: CreateDiaryEntry): Promise<DiaryEntry>;
  updateDiaryEntry(id: string, req: UpdateDiaryEntry): Promise<DiaryEntry>;
  deleteDiaryEntry(id: string): Promise<string>;
}

export const DiaryApiContext = createContext<DiaryContextProps>(undefined!);

export const DiaryApiProvider: FC = ({ children }) => {
  const axios = useAuth().getAuthAxios('diary');

  const getDiaryEntries = async (from: string, to?: string): Promise<DateEntries[]> => (
    (await axios.get<DateEntries[]>(
      joinUrl(
        config.api.baseUrl,
        'diary',
        `${from}${to ? `~${to}` : ''}`,
      ),
    )).data
  );

  const createDiaryEntry = async (date: string, req: CreateDiaryEntry): Promise<DiaryEntry> => (
    (await axios.post<DiaryEntry>(
      joinUrl(config.api.baseUrl, 'diary', date),
      req,
    )).data
  );

  const updateDiaryEntry = async (id: string, req: UpdateDiaryEntry): Promise<DiaryEntry> => (
    (await axios.put<DiaryEntry>(
      joinUrl(config.api.baseUrl, 'diary', id),
      req,
    )).data
  );

  const deleteDiaryEntry = async (id: string): Promise<string> => (
    (await axios.delete<string>(
      joinUrl(config.api.baseUrl, 'diary', id),
    )).data && id
  );

  return (
    <DiaryApiContext.Provider value={{ getDiaryEntries, createDiaryEntry, updateDiaryEntry, deleteDiaryEntry }}>
      {children}
    </DiaryApiContext.Provider>
  );
};
