import React, { FC } from 'react';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {
}

export const Login: FC<Props> = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  const loginButton = (
    <a
      href="/#"
      className="navbar-item"
      onClick={_.partial(loginWithRedirect, {
        appState: { returnTo: window.location.pathname },
      })}

    >
      <FontAwesomeIcon icon={faUserCircle} className="login-icon" />
    </a>
  );

  const profile = (
    <div className="navbar-item has-dropdown is-hoverable">
      <a href="/#" className="navbar-link">
        <figure className="image is-32x32">
          <img className="is-rounded" src={user?.picture} />
        </figure>
      </a>
      <div className="navbar-dropdown is-right">
        <span className="navbar-item has-text-weight-bold">{user?.name}</span>
        <span className="navbar-item">{user?.email}</span>
        <hr className="navbar-divider" />
        <a href="/#" className="navbar-item" onClick={() => logout({ returnTo: window.location.origin })}>
          <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        </a>
      </div>
    </div>
  );

  return !isAuthenticated ? loginButton : profile;
};
