import React, { FC } from 'react';
import { DiaryContextProvider } from './DiaryContext';
import { SideNav } from './SideNav';
import { Timeline } from './Timeline';

export const Diary: FC = () => (
  <DiaryContextProvider>
    <div className="diary">
      <div className="columns">
        <div className="column">
          <Timeline />
        </div>
        <div className="column is-one-quarter has-background-white-ter side-nav">
          <SideNav />
        </div>
      </div>
    </div>
  </DiaryContextProvider>
);
