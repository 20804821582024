import React, { FC } from 'react';
import { Win } from './Win';
import { Credit } from './Credit';

export const CreditPanel: FC = () => (
  <div className="columns is-centered">
    <div
      className="column is-one-third columns"
      style={{ paddingBottom: 0, marginBottom: -20 }}
    >
      <div className="column"><Win /></div>
      <div className="column " style={{ textAlign: 'right' }}><Credit /></div>
    </div>
  </div>
);
