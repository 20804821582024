/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { Led } from './Led';
import { FSM, useSlot } from './SlotContext';
import './Win.scss';

export const Win: FC = () => {
  const { win, fsm, collect } = useSlot();
  const isClickable = fsm === FSM.SETTLEMENT && win !== 0;

  return (
    <div
      className={`win ${isClickable ? 'is-clickable' : ''}`}
      onClick={isClickable ? collect : undefined}
    >
      <div className="name">Win</div>
      <div className="led"><Led digits={4} value={win} /></div>
    </div>
  );
};
