import React, { FC, useEffect, useState, useRef, useContext, createContext, MutableRefObject } from 'react';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';
import dayjs, { Dayjs } from 'dayjs';
import { DiaryEntry, CreateDiaryEntry, UpdateDiaryEntry, DateEntries } from '@cflin/interfaces';
import { useDiaryApi } from '../../utils/api';
import { getToday } from './utils';

export enum FSM {
  INIT,
  INITIALIZED,
}

const options = {
  initWindowSize: 30,
  windowSize: 20,
};

export interface ContextProps {
  today: Dayjs;
  fsm: FSM;
  entries: DateEntries[];
  loadOlder(page: number): Promise<void>;
  loadNewer(page: number): Promise<void>;
  addEntry(di: number, entry: CreateDiaryEntry): Promise<void>;
  updateEntry(di: number, ei: number, entry: UpdateDiaryEntry): Promise<void>;
  deleteEntry(di: number, ei: number): Promise<void>;
  anchor: MutableRefObject<HTMLDivElement>;
}

const DiaryContext = createContext<ContextProps>(undefined!);
export const useDiary = () => useContext(DiaryContext);

export const DiaryContextProvider: FC = ({ children }) => {
  const [fsm, setFsm] = useState<FSM>(FSM.INIT);
  const paramDate = useParams().date;
  const initDate = dayjs.utc(paramDate);
  const [entries, setEntries] = useState<DateEntries[]>([]);
  const anchor = useRef<HTMLDivElement>(undefined!);
  const { getDiaryEntries, createDiaryEntry, updateDiaryEntry, deleteDiaryEntry } = useDiaryApi();

  const init = async () => {
    const from = initDate.subtract(Math.floor(options.initWindowSize / 2), 'days');
    const to = from.add(options.initWindowSize - 1, 'days');
    setEntries(await getDiaryEntries(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')));
  };

  const loadNewer = async (page: number) => {
    const offset = Math.floor((options.initWindowSize + 1) / 2);
    const from = initDate.add(offset + options.windowSize * (page - 1), 'days');
    const to = from.add(options.windowSize - 1, 'days');
    setEntries(
      update(
        entries,
        { $push: await getDiaryEntries(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')) },
      ),
    );
  };

  const loadOlder = async (page: number) => {
    const offset = Math.floor(options.initWindowSize / 2) + 1;
    const to = initDate.subtract(offset + options.windowSize * (page - 1), 'days');
    const from = to.subtract(options.windowSize - 1, 'days');
    setEntries(
      update(
        entries,
        { $unshift: await getDiaryEntries(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')) },
      ),
    );
  };

  const addEntry = async (i: number, entry: CreateDiaryEntry): Promise<void> => {
    setEntries(
      update(
        entries,
        { [i]: { entries: { $splice: [[0, 0, await createDiaryEntry(entries[i].date, entry)]] } } },
      ),
    );
  };

  const updateEntry = async (di: number, ei: number, entry: DiaryEntry): Promise<void> => {
    setEntries(
      update(
        entries,
        { [di]: { entries: { [ei]: { $set: await updateDiaryEntry(entry.id, entry) } } } },
      ),
    );
  };

  const deleteEntry = async (di: number, ei: number): Promise<void> => {
    await deleteDiaryEntry(entries[di].entries[ei].id);
    setEntries(
      update(
        entries,
        { [di]: { entries: { $splice: [[ei, 1]] } } },
      ),
    );
  };

  useEffect(() => {
    (async () => {
      setFsm(FSM.INIT);
      await init();
      setFsm(FSM.INITIALIZED);
    })();
  }, [paramDate]);

  return (
    <DiaryContext.Provider
      value={{
        fsm,
        today: getToday(),
        entries,
        loadNewer,
        loadOlder,
        addEntry,
        deleteEntry,
        updateEntry,
        anchor,
      }}
    >
      {children}
    </DiaryContext.Provider>
  );
};
