import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { usePostApi } from '../../utils/api';

interface Props {
  path: string;
  likes: number;
  dislikes: number;
  tags: string[];
  votable?: boolean;
}

export const Footer: FC<Props> = ({ path, likes, dislikes, tags, votable = false }) => {
  const [upvotes, setUpvotes] = useState<number>(likes);
  const [downvotes, setDownvotes] = useState<number>(dislikes);
  const { upvote, downvote } = usePostApi();

  const onUpvote = votable ? async () => setUpvotes(await upvote(path)) : async () => {};
  const onDownvote = votable ? async () => setDownvotes(await downvote(path)) : async () => {};

  return (
    <div className="container columns">
      <div className="column is-one-fifth">
        <span className={`icon-text ${votable ? 'is-clickable' : ''}`} onClick={onUpvote}>
          <span className="icon">
            <i>
              <FontAwesomeIcon icon={faThumbsUp} />
            </i>
          </span>
          <span>{upvotes}</span>
        </span>
        <span className={`icon-text ml-3 ${votable ? 'is-clickable' : ''}`} onClick={onDownvote}>
          <span className="icon">
            <i>
              <FontAwesomeIcon icon={faThumbsDown} />
            </i>
          </span>
          <span>{downvotes}</span>
        </span>
      </div>
      <div className="column">
        <span className="tags is-right">
          {tags.map(t => <span className="tag is-info is-light" key={t}>{t}</span>)}
        </span>
      </div>
    </div>
  );
};
