/* eslint-disable */
import React, { FC, useState, useEffect, createContext, useContext } from 'react';
import _ from 'lodash';
import update from 'immutability-helper'
import { HIT_ICONS } from './Icon';
import { spin as spn } from './spin';

/**      reset()         credit()
 * INIT --------> IDLE  ---------> BETTING
 *                 ^                  | spin()
 *       collect() |                  v
 *             SETTLEMENT <------- SPINNING
 */
export enum FSM {
  INIT,
  IDLE,
  BETTING,
  SPINNING,
  SETTLEMENT,
}

export type Bet = 2 | 5 | 10 | 15 | 20 | 25 | 30 | 40 | 50 | 100;
export type Bets = Partial<Record<Bet, number>>;

interface ContextProps {
  fsm: FSM;
  credit: number;
  addCredit: (value: number) => void;
  win: number;
  collect: () => void;
  hits: number[];
  spin: () => void;
  bets: Bets;
  resetBets: () => void;
  betOn: (i: Bet) => void;
  rebet: () => void;
  canRebet: boolean;
}
export const INIT_BETS: Bets = {
  2: 0, 5: 0, 10: 0, 15: 0, 20: 0, 25: 0, 30: 0, 40: 0, 50: 0, 100: 0,
};

const SlotContext = createContext<ContextProps>(undefined!);
export const useSlot = () => useContext(SlotContext);

export const SlotContextProvider: FC = ({ children }) => {
  const [fsm, setFsm] = useState<FSM>(FSM.INIT);
  const [credit, setCredit] = useState<number>(0);
  const [win, setWin] = useState<number>(0);
  const [hits, setHits] = useState<number[]>([24]);
  const [bets, setBets] = useState<Bets>(INIT_BETS);
  const [prevBets, setPrevBets] = useState<Bets>(INIT_BETS);

  const delay = async (t: number) => new Promise(res => setTimeout(res, t));
  const reset = async () => {
    setFsm(FSM.INIT);
    for (const i of _.range(10).reverse()) {
      const p = Number(i.toString().repeat(4));
      setCredit(p);
      setWin(p);
      setHits(_.range(i % 2, 24, 2));
      const q = Number(i.toString().repeat(2));
      setBets(Object.keys(INIT_BETS).reduce((acc, k) => ({...acc, ...{[k]: q}}), {}));
      await delay(300);
    };
    setCredit(0);
    setWin(0);
    setHits([24]);
    setBets(Object.keys(INIT_BETS).reduce((acc, k) => ({...acc, ...{[k]: 0}}), {}));
    setFsm(FSM.IDLE);
  }
  const addCredit = () => {
    if (fsm !== FSM.IDLE && fsm !== FSM.BETTING) return;
    if (fsm === FSM.IDLE) setFsm(FSM.BETTING);
    setCredit(credit + 10);
  }
  const addWin = async (v: number) => {
    for (let i = 1; i <= v; i++) {
      setWin(win + i)
      await delay(20);
    }
  };
  const collect = () => {
    setCredit(credit + win);
    setWin(0);
    setBets(INIT_BETS);
    setFsm(FSM.BETTING);
  }
  const resetBets = () => setBets(INIT_BETS);
  const betOn = (b: Bet) => {
    if (fsm !== FSM.BETTING || credit <= 0) return;
    setCredit(credit - 1);
    setBets(update(bets, {
      [b]: { $set: bets[b]! + 1},
      ... b === 100 ? { 50: { $set: bets[50]! + 1 } } : {}
    }))
  };
  const rebetCost = Object.values(prevBets).reduce((a, b) => a! + b!)!;
  const canRebet =  fsm === FSM.BETTING &&
    credit >= rebetCost && rebetCost > 0 &&
    Object.values(bets).reduce((a, b) => a! + b!)! === 0;
  const spin = async () => {
    setPrevBets(bets);

    if (fsm !== FSM.BETTING) return;
    setFsm(FSM.SPINNING);
    // spinning
    const _hits = await spn(hits[0], setHits);

    const _betHilights = {...INIT_BETS};
    _hits.forEach(h => !!HIT_ICONS[h] && _betHilights[HIT_ICONS[h]]++);

    // settling
    const _win = Object.entries(_betHilights).reduce((acc, [x, b]) =>
      acc + bets[x] * b! * Number(x),
      0
    )
    await addWin(_win);
    setFsm(
      _win !== 0 ? FSM.SETTLEMENT :
      credit === 0 ? FSM.IDLE :
      FSM.BETTING
    );
    if (_win === 0) setBets(INIT_BETS);
  };
  const rebet = async () => {
    const cost = Object.values(prevBets).reduce((a, b) => a! + b!)!;
    setCredit(credit - cost);
    setBets(prevBets);
  }

  useEffect(() => {
    (async () => {
      await reset();
    })()
  }, []);

  return (
    <SlotContext.Provider value={{
      fsm,
      credit, addCredit,
      win,
      collect, hits, spin,
      bets, resetBets, betOn, rebet, canRebet,
    }}>
      {children}
    </SlotContext.Provider>
  );
};
