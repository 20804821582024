/* eslint-disable */
import React, { FC } from 'react';
import _ from 'lodash';
import { FSM, useSlot } from './SlotContext';

export const ControlPanel: FC = () => {
  const { fsm, spin, credit, bets, canRebet, rebet } = useSlot();
  const spinDisabled = fsm !== FSM.BETTING || _.sum(Object.values(bets)) == 0;
  const rebetDisabled = fsm ;

  return (
    <div className="control-panel">
      <div className="buttons is-centered">
        <button className="button is-info is-outlined" disabled={!canRebet} onClick={rebet}>
          REBET
        </button>
        <button className="button is-info" disabled={spinDisabled} onClick={spin}>
          SPIN
        </button>
      </div>
    </div>
  );
}
