import React, { FC, useState } from 'react';
import { DiaryEntry, UpdateDiaryEntry } from '@cflin/interfaces';
import { useDiaryApi } from '../../utils/api';
import { Editor } from './Editor';
import { Entry } from './Entry';
import { useDiary } from './DiaryContext';

interface Props {
  dateIndex: number;
  entryIndex: number;
  entry: DiaryEntry;
}

export const EntryContainer: FC<Props> = ({ dateIndex, entryIndex, entry }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const { updateDiaryEntry } = useDiaryApi();
  const { updateEntry, deleteEntry } = useDiary();
  const onSavingUpdatedEntry = async (e: UpdateDiaryEntry): Promise<void> => {
    await updateEntry(dateIndex, entryIndex, await updateDiaryEntry(entry.id, e));
    setEditing(false);
  };
  const onDeleteEntry = async (): Promise<void> => {
    await deleteEntry(dateIndex, entryIndex);
  };

  return (
    editing ?
      <Editor
        entry={entry}
        onSave={onSavingUpdatedEntry}
        onCancel={async () => setEditing(false)}
      /> :
      <Entry
        entry={entry}
        onEdit={async () => setEditing(true)}
        onDelete={onDeleteEntry}
        key={entry.id}
      />
  );
};
