import React, { FC, createContext } from 'react';
import { Recipe, CreateRecipe, UpdateRecipe } from '@cflin/interfaces';
import { config } from '@cflin/config';
import joinUrl from 'url-join';
import axios from 'axios';
import { useAuth } from '../auth';

export interface RecipeApiContextProps {
  getRecipe: (id: string) => Promise<Recipe>;
  getRecipes: () => Promise<Recipe[]>;
  createRecipe: (req: CreateRecipe) => Promise<string>;
  updateRecipe: (id: string, req: UpdateRecipe) => Promise<string>;
  deleteRecipe: (id: string) => Promise<string>;
}

export const RecipeApiContext = createContext<RecipeApiContextProps>(undefined!);
export const RecipeApiProvider: FC = ({ children }) => {
  const authAxios = useAuth().getAuthAxios('recipes');

  const getRecipes = async (): Promise<Recipe[]> => (
    (await axios.get<Recipe[]>(joinUrl(config.api.baseUrl, 'recipes'))).data
  );

  const getRecipe = async (id: string): Promise<Recipe> => (
    (await axios.get<Recipe>(joinUrl(config.api.baseUrl, 'recipes', id))).data
  );

  const createRecipe = async (req: CreateRecipe): Promise<string> => (
    (await authAxios.post<string>(joinUrl(config.api.baseUrl, 'recipes'), req)).data
  );

  const updateRecipe = async (id: string, req: UpdateRecipe): Promise<string> => (
    (await authAxios.put<string>(joinUrl(config.api.baseUrl, 'recipes', id), req)).data
  );

  const deleteRecipe = async (id: string): Promise<string> => (
    (await authAxios.delete<string>(joinUrl(config.api.baseUrl, 'recipes', id))).data
  );

  return (
    <RecipeApiContext.Provider value={{ getRecipe, getRecipes, createRecipe, updateRecipe, deleteRecipe }}>
      { children }
    </RecipeApiContext.Provider>
  );
};
