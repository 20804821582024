import React, { FC, useState } from 'react';
import { DiaryEntry } from '@cflin/interfaces/diary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ConfirmAlert } from '../../utils/confirmAlert';
import './Entry.scss';

interface Props {
  entry: DiaryEntry;
  onEdit(): Promise<void>;
  onDelete(): Promise<void>;
}

export const Entry: FC<Props> = ({ entry, onEdit, onDelete }) => {
  const [showDeletionAlert, setShowDeletionAlert] = useState<boolean>();
  const activateDeletionAlert = () => setShowDeletionAlert(true);
  const deactivateDeletionAlert = () => setShowDeletionAlert(false);

  return (
    <div className="entry">
      <article className="message box is-info">
        <div className="message-header">
          <span>{entry.title}</span>
          <span>
            <span className="edit-icon">
              <FontAwesomeIcon icon={faList} onClick={onEdit} />
            </span>
            <span className="del-icon">
              <FontAwesomeIcon icon={faTrashAlt} onClick={activateDeletionAlert} />
            </span>
          </span>
        </div>
        <div className="message-body">
          {entry.content.split('\n').map((s, i) => (
            // eslint-disable-next-line
            <React.Fragment key={s + i}>
              {s === '---' ? <hr /> : <>{s}<br /></>}
            </React.Fragment>
          ))}
        </div>
      </article>
      <div>
        {entry.tags.map(tag => (
          <span className="tag is-warning is-small box" key={tag}>
            {tag}
          </span>
        ))}
      </div>
      {showDeletionAlert &&
        <ConfirmAlert
          title="Deleting Entry"
          confirmText="Yes"
          onConfirm={async () => {
            await onDelete();
          }}
          onCancel={async () => {
            deactivateDeletionAlert();
          }}
        >
          <div>Are you sure you want to delete the entry titled <b>{entry.title}</b>?</div>
        </ConfirmAlert>}
    </div>
  );
};
