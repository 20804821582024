import React, { FC } from 'react';
import { DatePicker } from './DatePicker';
import './SideNav.scss';

export const SideNav: FC = () => (
  <aside className="side-nav">
    <div className="section">
      <DatePicker />
    </div>
  </aside>
);
