import React, { FC, createContext } from 'react';
import { Post, CreatePost, UpdatePost, PostPreview } from '@cflin/interfaces';
import { config } from '@cflin/config';
import axios from 'axios';
import joinUrl from 'url-join';
import { useAuth } from '../auth';

const BASE_URL = joinUrl(config.api.baseUrl, 'posts');

export interface PostApiContextProps {
  createPost: (path: string, req: CreatePost) => Promise<void>;
  updatePost: (path: string, req: UpdatePost) => Promise<void>;
  getPost: (path: string) => Promise<Post>;
  getDirectory: (path: string) => Promise<PostPreview[]>;
  deletePost: (path: string) => Promise<void>;
  upvote: (path: string) => Promise<number>;
  downvote: (path: string) => Promise<number>;
}

export const PostApiContext = createContext<PostApiContextProps>(undefined!);

export const PostApiProvider: FC = ({ children }) => {
  const authAxios = useAuth().getAuthAxios('posts');

  const createPost = async (path: string, req: CreatePost): Promise<void> => {
    await authAxios.post<void>(
      joinUrl(BASE_URL, path),
      req,
    );
  };

  const updatePost = async (path: string, req: UpdatePost): Promise<void> => {
    await authAxios.put<void>(
      joinUrl(BASE_URL, path),
      req,
    );
  };

  const getPost = async (path: string): Promise<Post> => (
    (await axios.get<Post>(joinUrl(config.api.baseUrl, 'posts', path))).data
  );

  const getDirectory = async (path: string): Promise<PostPreview[]> => {
    if (!path.endsWith('/')) throw new Error('Invalid Path');
    return (await axios.get<PostPreview[]>(joinUrl(BASE_URL, path))).data;
  };

  const deletePost = async (path: string): Promise<void> => {
    await authAxios.delete(joinUrl(BASE_URL, path));
  };

  const upvote = async (path: string): Promise<number> => (
    (await axios.patch<Post>(joinUrl(BASE_URL, path), { upvote: 1 })).data.likes
  );

  const downvote = async (path: string): Promise<number> => (
    (await axios.patch<Post>(joinUrl(BASE_URL, path), { downvote: 1 })).data.dislikes
  );

  return (
    <PostApiContext.Provider
      value={{
        getPost,
        getDirectory,
        createPost,
        updatePost,
        deletePost,
        upvote,
        downvote,
      }}
    >
      {children}
    </PostApiContext.Provider>
  );
};
