import React, { FC } from 'react';
import FileType, { FileExtension, MimeType } from 'file-type/browser';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import './ImgDropzone.scss';

const ALLOWED_EXTENTIONS: (FileExtension | undefined)[] = ['jpg', 'png', 'gif'];
const ALLOWED_MIME_TYPES: (MimeType | undefined)[] = ['image/jpeg', 'image/png', 'image/gif'];

export interface ImgDropzoneProps {
  src?: string;
  onDrop: (url: string) => void;
  onDelete: () => void;
}

export const ImgDropzone: FC<ImgDropzoneProps> = ({ src, onDrop, onDelete }) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: async (files: File[]) => {
      // validate the file type
      const { ext, mime } = await FileType.fromBlob(files[0]) || {};
      if (!ALLOWED_EXTENTIONS.includes(ext)) throw Error('Invalid extension.');
      if (!ALLOWED_MIME_TYPES.includes(mime)) throw Error('Invalid Mime type.');

      onDrop(URL.createObjectURL(files[0]));
    },
  });

  const preview = src ? (
    <div className="preview">
      {
        // eslint-disable-next-line
      }<button className="delete is-pulled-right" onClick={(e) => { e.stopPropagation(); onDelete(); }} />
      <img src={src} />
    </div>
  ) :
    <FontAwesomeIcon icon={faImage} className="has-text-grey-light" size="4x" />;

  return (
    <div className="img-dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept=".jpg, .png, .gif" />
      { preview }
    </div>
  );
};
