import React, { FC } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { RecipeIngredientGroup as IngredientGroup } from '@cflin/interfaces';

interface Props {
  ingredientGroups: IngredientGroup[];
  setIngredientGroups: (ingredientGroups: IngredientGroup[]) => void;
}

export const IngredientGroups: FC<Props> = ({ ingredientGroups, setIngredientGroups }) => {
  const newIngredientGroup = () => setIngredientGroups(
    update(ingredientGroups, {
      $push: [
        { groupName: '', ingredients: [{ name: '', quantity: '' }] },
      ],
    }),
  );
  const deleteIngredientGroup = (i: number) => setIngredientGroups(
    update(ingredientGroups, {
      $splice: [[i, 1]],
    }),
  );
  const updateIngredientGroupName = (i: number, name: string) => setIngredientGroups(
    update(ingredientGroups, {
      [i]: { groupName: { $set: name } },
    }),
  );
  const newIngredient = (gi: number, ii: number) => setIngredientGroups(
    update(ingredientGroups, {
      [gi]: { ingredients: { $splice: [[ii + 1, 0, { name: '', quantity: '' }]] } },
    }),
  );
  const deleteIngredient = (gi: number, ii: number) => setIngredientGroups(
    update(ingredientGroups, {
      [gi]: { ingredients: { $splice: [[ii, 1]] } },
    }),
  );
  const updateIngredientName = (gi: number, ii: number, name: string) => setIngredientGroups(
    update(ingredientGroups, {
      [gi]: { ingredients: { [ii]: { name: { $set: name } } } },
    }),
  );
  const updateIngredientQuantity = (gi: number, ii: number, quantity: string) => setIngredientGroups(
    update(ingredientGroups, {
      [gi]: { ingredients: { [ii]: { quantity: { $set: quantity } } } },
    }),
  );

  return (
    <div className="ingredient-groups">
      {ingredientGroups.map((ingredientGroup, gi) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="ingredient-group field" key={gi}>
          <div className="field control level">
            <div className="level-left">
              <input
                className="input is-small"
                placeholder="ingredient group"
                value={ingredientGroup.groupName}
                onChange={e => updateIngredientGroupName(gi, e.target.value)}
              />
            </div>
            <div className="level-right">
              <FontAwesomeIcon
                className={`op-button ${ingredientGroups.length <= 1 ? 'disabled' : ''}`}
                icon={faTrashAlt}
                onClick={ingredientGroups.length <= 1 ? () => {} : _.partial(deleteIngredientGroup, gi)}
              />
            </div>
          </div>
          {ingredientGroup.ingredients.map((ingredient, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field is-horizontal" key={i}>
              <div className="field-body">
                <div className="field">
                  <div className="control is-expanded">
                    <input
                      className="input is-small"
                      type="text"
                      placeholder="ingredient"
                      value={ingredient.name}
                      onChange={e => updateIngredientName(gi, i, e.target.value)}
                    />
                  </div>
                </div>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <input
                      className="input is-small"
                      type="text"
                      placeholder="quantity"
                      value={ingredient.quantity}
                      onChange={e => updateIngredientQuantity(gi, i, e.target.value)}
                    />
                  </div>
                  <div className="control">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="op-button"
                      onClick={_.partial(newIngredient, gi, i)}
                    />
                    <FontAwesomeIcon
                      className={`op-button ${ingredientGroup.ingredients.length <= 1 ? 'disabled' : ''}`}
                      icon={faTrashAlt}
                      onClick={ingredientGroup.ingredients.length <= 1 ? undefined : _.partial(deleteIngredient, gi, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className="field">
        <div className="control add-ingredient-group">
          <FontAwesomeIcon
            icon={faPlus}
            className="op-button"
            onClick={newIngredientGroup}
          />
        </div>
      </div>
    </div>
  );
};
