import React, { useState, useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { TimelineItem } from './TimelineItem';
import { FSM, useDiary } from './DiaryContext';
import 'bulma-timeline/dist/css/bulma-timeline.min.css';

export const Timeline: FC = () => {
  const paramDate = useParams().date;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const { fsm, entries, loadNewer, loadOlder, anchor } = useDiary();

  const timelineItems = entries.map((dateEntry, i) => (
    <TimelineItem
      date={dateEntry.date}
      entries={dateEntry.entries}
      dateIndex={i}
      anchor={paramDate === dateEntry.date ? anchor : undefined}
      key={dateEntry.date}
    />
  ));

  useEffect(() => {
    switch (fsm) {
      case FSM.INIT:
        setHasMore(false);
        break;
      case FSM.INITIALIZED:
        window.scrollTo({
          top: window.pageYOffset + anchor.current.getBoundingClientRect().top - 52,
        });
        setHasMore(true);
        break;
      default:
    }
  }, [fsm]);

  return (
    <div className="timeline">
      <div className="section">
        <InfiniteScroll threshold={800} loadMore={loadOlder} hasMore={hasMore} isReverse key={paramDate}>
          <InfiniteScroll threshold={800} loadMore={loadNewer} hasMore={hasMore} key={paramDate}>
            {timelineItems}
          </InfiniteScroll>
        </InfiniteScroll>
      </div>
    </div>
  );
};
