import React, { FC, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BulmaCalendar, { Options, Event } from 'bulma-calendar';
import './DatePicker.scss';
import dayjs from 'dayjs';

/**
 * Somehow the Bulma Calendar select event doesn't work after 6.0.9.
 * Fixed the version to 6.0.7.
 */

export const DatePicker: FC = () => {
  const paramDate = useParams().date;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(undefined!);
  const options: Options = {
    type: 'date',
    displayMode: 'inline',
    showHeader: false,
    showFooter: false,
    minDate: new Date(0),
    startDate: dayjs(paramDate).toDate(),
  };

  useEffect(() => {
    new BulmaCalendar(ref.current, options)
      .on('select', (e: Event) => {
        const date: Date = e.data.startDate;
        const y = date.getFullYear().toString().padStart(4, '0');
        const m = (date.getMonth() + 1).toString().padStart(2, '0');
        const d = date.getDate().toString().padStart(2, '0');
        navigate(`/diary/${y}-${m}-${d}`);
      });
  }, []);
  return (
    <div ref={ref} />
  );
};
